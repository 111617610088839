import React from 'react';

function Spacer(props) {
  return (
        <section className="spacer" style={props}>
        </section>
  );
}

export default Spacer;
