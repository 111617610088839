import React from 'react';

import Container from '../molecules/container';
import Spacer from '../molecules/spacer';

function Imprint() {
  return (
        <div className="main">
            <Spacer height={'5rem'}/>
            <Container>
                <p>Yvonne Vogt&nbsp;&nbsp;//&nbsp;&nbsp;UX &amp; UI Design</p>
                <p>
                    <a href="mailto:yvonne.vogt@siebenelf.com">yvonne.vogt@siebenelf.com</a><br />
                    <a href="tel:+4917664930711">+49 176.64930711</a>
                </p>
                <p>
                    Frankfurter Straße 4<br/>
                    70376 Stuttgart 
                </p>
                <br/>
                <h3>Data Privacy</h3>
                <p>
                    Your data is safe, here are no cookies.
                </p>
                <br/>
                <br/>
                <br/>
                <p>
                    This website is used for application purposes only.
                </p>
            </Container>
        </div>
  );
}

export default Imprint;
