import React from 'react';

function InfoRight(props) {
    const className = props.class ? props.class : "";

    return (
        <section className={"infoRight "+className}>
            <div className="infoColumn">
                {props.children}
            </div>
            {props.image && 
                <div className="imageColumn">
                    <img src={props.image.src} alt={props.image.alt} style={props.image.style}/> 
                    {props.button &&
                        props.button
                    }
                </div>
            }
            {props.content && 
                props.content
            }
        </section>
    );
}

export default InfoRight;
