import React from 'react';

function Analysis(props) {
    const className = props.class ? props.class : "";

    return (
        <section className={"analysis "+className}>
            {props.children}
        </section>
    );
}

export default Analysis;
