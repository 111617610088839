import React from 'react';
import Abstract from '../molecules/abstract';
import InfoLeft from '../molecules/infoLeft';
import InfoRight from '../molecules/infoRight';
import Image from '../molecules/image';
import Container from '../molecules/container';
import ImageList from '../molecules/imageList';
import Spacer from '../molecules/spacer';
import PageNavigation from '../molecules/pageNavigation';

import ImageButton from '../atoms/ImageButton';

function Vela() {
    return (
        <div className="main">
            <PageNavigation links={
                [
                    { 'title': 'VELA', 'anchor': 'vela-forecast'}, 
                    { 'title': 'Competitive Analysis', 'anchor': 'competitive-analysis' },
                    { 'title': 'Business Requirements', 'anchor': 'business-requirements'},
                    { 'title': 'User Personas and Journeys', 'anchor': 'user-personas-journeys' },
                    { 'title': 'Information Architecture', 'anchor': 'information-architecture' },
                    { 'title': 'Wireframing', 'anchor': 'wireframing' },
                    { 'title': 'Usability Test Report', 'anchor': 'usability-test-report' },
                    { 'title': 'Visual Design', 'anchor': 'visual-design' }
                ]
            }/>
            <Abstract>
                <img id="vela-forecast" src={process.env.PUBLIC_URL+'/img/vela.svg'} alt="VELA" style={{marginBottom: '3rem'}}/>
                <p>
                    VELA is your customized weather forecast app focussed on outdoor activities that helps you to find your spots and tracks with the perfect conditions.
                </p>
                <p>
                    Wether you're into kite surfing, hiking, biking or something else, VELA helps you to find the perfect place, the perfect weather, the perfect time. 
                </p>
                <p>
                    I created this project for my professional development course at Career Foundry.
                </p>
            </Abstract>
            <Spacer height={'2rem'}/>
            <InfoLeft image={{src: process.env.PUBLIC_URL+'/img/vela_mockup_gespigelt_glow.png', alt:'VELA', style:{top: '-20rem', left: '-13rem', marginTop: '-15rem', marginBottom: '-15rem', maxWidth: '180%'}}}>
                <h3>Goal</h3>
                <p>
                    An app which shows at a glance and in an appealing informative design the current weather forecast and helps you to find your perfect spots and tracks for your skill level.
                </p>
                <br/>
                <h3>Problem</h3>
                <p>
                    How to create a weather forecast app that isn’t overwhelming the variety of conditions that each outdoor activity needs?
                </p>
            </InfoLeft>
            <Spacer height={'10rem'}/>
            <InfoRight image={{src: process.env.PUBLIC_URL+'/img/Design_Process.png', alt:'Design Process', style:{maxWidth: '100%', top: '3rem'}}}>
                <h3>My Role</h3>
                <p>
                    Being part of my 5-month Career Foundry intensive UX course, I was the product owner of the whole strategy, responsible for UX research, Information architecture, user testing, user interface, visual design and branding.
                </p>
                <br/>
                <h3>Used Tools</h3>
                <div style={{ display: 'flex' }}>
                    <ul style={{ paddingRight: '1em' }}>
                        <li>Pen &amp; Paper</li>
                        <li>Balsamiq</li>
                        <li>Sketch</li>
                        <li>Adobe XD</li>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>Typeform</li>
                        <li>Survey Monkey</li>
                        <li>Google Forms</li>
                        <li>Zoom</li>
                    </ul>
                    <ul>
                        <li>Jira</li>
                        <li>Confluence</li>
                        <li>Trello</li>
                        <li>Miro</li>
                        <li>Coggle</li>
                        <li>Usability Hub</li>
                        <li>Optimal Workshop</li>
                        <li>Excel</li>
                        <li>Power Point</li>
                        <li>Word</li>
                        <li>Draw.io</li>
                    </ul>
                </div>
            </InfoRight>
            <Spacer height={'7rem'}/>
            <Abstract class="a4">
                <h1 id="competitive-analysis">Competitive analysis</h1>
                <p>
                    For my study project VELA I did a competitive analysis to get to know your competitors. I analysed a few other wind and weather forecast supplier. 
                    If you want to know more about a big player check my competitor analysis of <a class="external" href="https://windfinder.com">windfinder.com</a>.
                </p>
            </Abstract>
            <Spacer height={'2rem'}/>
            <Image src={process.env.PUBLIC_URL+'/img/swot-graphic.svg'} alt='SWOT' class="a6"/>
            <Container>
                <h3>Strengths</h3>
                <ul>
                    <li>Its competence for wind, waves and weather.</li>
                    <li>The possibility to define pre-sets for different sports, depending on the specific needs and abilities.</li>
                    <li>Optimized data transfer to ensure quicker downloads on the go from any mobile device.</li>
                </ul>
                <br/>
                <h3>Weaknesses</h3>
                <ul>
                    <li>If you’re new to the wind, waves and weather forecasts, the visualisations and informations are quite overwhelming, but given the time, you’ll understand it eventually.</li>
                    <li>Windfinder might not be the right choice if you’re unsure of the perfect conditions.</li>
                    <li>Their social media channels need some fresh air and a straight line and there are other forecast apps which have a better UI and UX as well as iconography.</li>
                </ul>
                <br/>
                <h3>Opportunities</h3>
                <ul>
                    <li>If they would do more on their social media channels.</li>
                    <li>for example some cooperation with professional athletes – they could generate more traffic.</li>
                    <li>Or they could promote a top spots of the week for kite surfers.</li>
                </ul>
                <br/>
                <h3>Threats</h3>
                <ul>
                    <li>There are some competitors which have a much better social media performance like windy.app or PredictWind.</li>
                    <li>On their blog or social media channels they work with professional water sports athletes.</li>
                    <li>They show their top surfing spots or how to stay cool in times like these.</li>
                    <li>The user interface as well as the usability is more appealing and easier to understand.</li>
                </ul>
                <br/>
                <br/>
                <br/>
                <ImageButton href={process.env.PUBLIC_URL+'/docs/competivite_analysis.pdf'}>
                    <img src={process.env.PUBLIC_URL+'/img/read-analysis.svg'} alt="Read Analysis"/>
                </ImageButton>
            </Container>
            <Spacer height={'3rem'}/>
            <Container>
                <h1 id="business-requirements">Business requirements</h1>
                <h3>Target Audience</h3>
                <p>
                    VELA’s target audience is actually everyone who loves the combination of wind, water and sports. This could be diving, sailing, surfing, kiting, fishing, …
                </p>
                <p>
                    My target group is very open minded, loves a good ride on a wave and wants to take a picture or video if it. 
                    As the app covers a very wide user base, both in age range as in income, the target group consist of people from 16 to 99+. 
                    Younger people will probably not go on a kite trip alone, older might lack the physical strength to handle their yacht.
                </p>
            </Container>
            <Image src={process.env.PUBLIC_URL+'/img/Risk_Opportunity.png'} alt='Risk &amp; Opportunity' class="a4"/>
            <Container>
                <h3>Competition</h3>
                <p>
                    There are a few really good competitors like Windfinder or Windy. Both are very popular and established. 
                    Windfinder was one of the first forecast apps on the market. Their maps are always state of the art and they offer a good weather forecast. 
                    Their weakness however is their social media presence. They lack polishing and appealing content. 
                </p>
                <p>
                    Windy is relatively new on the forecast market and also quite popular. Its social media presence is great, and their forecasts are too.
                </p>
                <p>
                    But apps like Windfinder, Windy or Windguru have one big problem. Their forecast informations are really complicated and overwhelming for unexperienced users.    
                </p>
                <br/>
                <h3>Risk / Opportunity</h3>
                <p>
                    While other apps aren’t using their full potential, they have a good market penetration. VELA might not be able to gain market shares fast enough to win users before they react.
                </p>
                <p>
                    Existing apps however focus on certain sports or at least a narrow niche. Vela could use its approach of combining all water based sports and create a meta community by diffusing and softening borders.
                </p>
            </Container>
            <InfoRight class="a3" image={{src: process.env.PUBLIC_URL+'/img/conclusion_brain.svg', alt:'Conclusion', style:{maxWidth: '200%', top: '3rem', right: '0'}}}>
                <h3>Conclusion</h3>
                <ul>
                    <li>VELA needs to stand out with detailed and easily understandable forecasts.</li>
                    <li>It has to offer a variety of activities and needs to know their specific requirements.</li>
                    <li>Usability and user interface of the app as well as of the website have to be on point.</li>
                    <li>VELA has to create an appealing social media presence including stunning pictures and fascinating blogposts and on Instagram/Facebook to get in touch with their community.</li>
                    <li>Users need to be able to share their own stories and pictures directly via the app, tagging the VELA community</li>
                </ul>
            </InfoRight>
            <Spacer height={'3rem'}/>
            <Container>
                <h1 id="user-personas-journeys">User personas and journeys</h1>
                <p>
                    It’s very important to understand who will be using the product. 
                    In order to solve a real user problem, you need to have a clear problem statement in mind; in order to write this problem statement, 
                    you first need to understand your users and their needs.
                </p>
                <h3>Who are the users and what do they need?</h3>
                <p>
                    I created user personas to understand what my users really need. <br/>
                    To get a grasp of them I needed to ask some questions. 
                </p>
                <p>
                    What I asked them was (simplified):
                </p>
                <ul>
                    <li>Who are they?</li>
                    <li>What is their main goal?</li>
                    <li>What is their main barrier to achieving this goal?</li>
                </ul>
                <p>
                    The following user personas are the result of my interviews and surveys.
                </p>
                <h3>Point of View</h3>
                <p>
                    Two distinct personas emerged from the research: Kim, the ambitious outdoor activity enthusiast who spends every free minute in the nature and Paul, 
                    who loves to be outside and enjoy every moment. Kim and Paul are an integral part of the ideation.
                </p>
            </Container>
            <Spacer height={'3rem'}/>
            <InfoRight image={{src: process.env.PUBLIC_URL+'/img/bild_kim.png', alt:'Kim Legner', style:{maxWidth: '200%', position: 'relative'}}} class="infoBottom">
                <h3>“In my next life I want to become a professional surfer. But for now I want to be able to have the tools like a pro”</h3>
                <p>
                    Kim Legner, 28 years<br/>
                    surfer, kitesurfer, diver and hiker
                </p>
                <ImageButton href={process.env.PUBLIC_URL+'/docs/kims-profile.pdf'}>
                    <img src={process.env.PUBLIC_URL+'/img/kims_profile.svg'} alt="Kim's Profile"/>
                </ImageButton>
            </InfoRight>
            <Spacer height={'3rem'}/>
            <InfoLeft image={{src: process.env.PUBLIC_URL+'/img/bild_paul.png', alt:'Paul Hofmann', style:{maxWidth: '200%', position: 'relative'}}} class="infoBottom">
                <h3>“I want to life in the moment and enjoy it with people I like, at places I’ve never been before and making new experiences”</h3>
                <p>
                    Paul Hofmann, 34 years <br/>
                    surfer and mountain biker
                </p>
                <ImageButton href={process.env.PUBLIC_URL+'/docs/pauls-profile.pdf'}>
                    <img src={process.env.PUBLIC_URL+'/img/pauls_profile.svg'} alt="Paul's Profile"/>
                </ImageButton>
            </InfoLeft>
            <Spacer height={'3rem'}/>
            <Container>
                <h1 id="information-architecture">Information Architecture</h1>
                <h3>Build the structure</h3>
                <p>
                    Sitemaps are a common and important tool in the information architecture and an integral part in UX. 
                    The structure of VELA has co-existing hierarchies. I analysed other websites’ structure and have derived and developed my structure from it.
                </p>
            </Container>
            <Spacer height={'5rem'}/>
            <Image src={process.env.PUBLIC_URL+'/img/coggle_diagram.svg'} alt='Build the structure' class="a6"/>
            <Spacer height={'3rem'}/>
            <Container>
                <p>
                    As I moved on creating my business requirement document, I documented all app components in a diagram. 
                    This helped defining my sitemap and the general app structure.
                </p>
            </Container>
            <Image src={process.env.PUBLIC_URL+'/img/sitemap.svg'} alt='Sitemap' class="a6"/>
            <Container>
                <ImageButton href={process.env.PUBLIC_URL+'/docs/sitemap.pdf'} style={{paddingBottom: '1em'}}>
                    <img src={process.env.PUBLIC_URL+'/img/sitemap-button.svg'} alt="Sitemap"/>
                </ImageButton>
                <p>
                    I verified my app structure via card sorting with a group of fifteen users and it was confirmed to be on point. After the usability test I renamed some areas though.
                </p>
            </Container>
            <Container>
                <h1 id="wireframing">Wireframing</h1>
                <h3>Giving VELA a Face</h3>
                <p>
                    Based on what I learned, I started sketching some ideas for my user flows. 
                    After some ideation and simple sketches, I created wireframes and developed a low-fidelity wireframe for the first user test.
                </p>
                <p>
                    Here is my evolution of one page from first sketch to final mockup.
                </p>
            </Container>
            <ImageList>
                <img src={process.env.PUBLIC_URL+"/img/02_Mobile Sketches.jpg"} alt="scribble"/>
                <img src={process.env.PUBLIC_URL+"/img/Mobile-wireframe.png"} alt="wireframe"/>
                <img src={process.env.PUBLIC_URL+"/img/18 Spot – 1.png"} alt="scribble"/>
                <img src={process.env.PUBLIC_URL+"/img/Detailed Spot - Forecast.png"} alt="scribble"/>
                <img src={process.env.PUBLIC_URL+"/img/Detailed Spot - Forecast – 1.png"} alt="scribble"/>
            </ImageList>
            <Container>
                <p>
                    Polishing the design explains the journey from the first sketch to the final design while keeping close contact to the testers. 
                    Via interviews and surveys I closed in on the (for now) best throw to VELAs UI. 
                </p>
                <br/>
                <ImageButton href={process.env.PUBLIC_URL+'/docs/polishing_the_design.pdf'}>
                    <img src={process.env.PUBLIC_URL+'/img/polishing_the_design.svg'} alt="Polishing the Design"/>
                </ImageButton>
            </Container>
            <Spacer height={'5rem'}/>
            <InfoLeft class="infoCenter a3"
                content={
                    <video width="300" controls style={{ position: 'relative' }}>
                            <source src="vid/200903_UX Immersion_6_6.webm" type="video/webm"/>
                            <source src="vid/200903_UX Immersion_6_6.mp4" type="video/mp4"/>
                    </video>
                }>
                <p>
                    VELA improved a lot through Gestalt Principles and the Psychology of Colours as well as Emotional Design Principles. 
                    The actual visual design started to take shape and VELA shifted from a wireframe state to a proper mockup.
                </p>
            </InfoLeft>
            <Container>
                <h1 id="usability-test-report">Usability Test Report</h1>
                <p>
                    After testing the prototype, I had a good overview of what works well and on which parts I should put our focus on. 
                    The onboarding process didn’t work as good as I thought as well as the process to set activity and skill level. 
                    I also revised the whole navigation of the app and most of the par-ticipants had problems to understand the forecast map. 
                    So after evalua-ting the results, I had 5 issues to revise in: activity and skill level setting, forecast map etc.
                </p>
                <p>
                    The detailed results and findings can be found in the PDF linked below.
                </p>
            </Container>
            <Image src={process.env.PUBLIC_URL+'/img/user-test-report-gallery.png'} alt='User Test Report' class="a4"/>
            <Container>
                <ImageButton href={process.env.PUBLIC_URL+'/docs/user-test-report.pdf'}>
                    <img src={process.env.PUBLIC_URL+'/img/test_report.svg'} alt="Polishing the Design"/>
                </ImageButton>                
            </Container>
            <Spacer height={'5rem'}/>
            <Container>
                <h1 id="visual-design">Visual Design</h1>
            </Container>
            <Image src={process.env.PUBLIC_URL+'/img/MockUp.png'} alt="Visual Design" class="a6" img={{style: {maxWidth:'100%'}}}/>
            <Container>
                <h3>Visual Design and Branding</h3>
                <p>
                    By the end of the usability testing phase and after identifying and fixing the potential issues I conducted an A/B testing. 
                    With the results I gathered here, I was able to redefine the design of VELA.
                </p>
            </Container>
            <Spacer height={'3rem'}/>
            <Image src={process.env.PUBLIC_URL+'/img/style_guide.jpg'} alt="Style Guide" class="a6" img={{style: {maxWidth:'100%'}}}/>
            <Container>
                <ImageButton href={process.env.PUBLIC_URL+'/docs/style_guide.pdf'}>
                    <img src={process.env.PUBLIC_URL+'/img/read_style_guide.svg'} alt="Style Guide"/>
                </ImageButton>                
            </Container>
            <Spacer height={'5rem'}/>
            <Image src={process.env.PUBLIC_URL+'/img/vela_mockup_bottom.jpg'} alt="VELA Mockups" class="a6" img={{style: {maxWidth:'100%'}}}/>
            <Spacer height={'5rem'}/>
        </div>
    );
}

export default Vela;
