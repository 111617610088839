import React from 'react';

function Image(props) {
  const styleDef = props.style ? props.style : {};
  const imageStyleDef = props.img && props.img.style ? props.img.style : {};

  return (
        <section className={"image "+props.class} style={styleDef}>
          {props.link && 
            <a href={props.link}>
              <img src={props.src} alt={props.alt} style={imageStyleDef}/>          
            </a>
          }
          {props.link === undefined &&
            <img src={props.src} alt={props.alt} style={imageStyleDef}/>
          }
        </section>
  );
}

export default Image;
