import React from 'react';

import InfoLeft from '../molecules/infoLeft';
import Spacer from '../molecules/spacer';

import ImageButton from '../atoms/ImageButton';

function About() {
  return (
        <div className="main">
            <Spacer height={'5rem'}/>
            <InfoLeft class="a3 imageTop" 
                content={
                    <div style={{justifyContent: 'center', alignContent: 'start', display: 'grid'}}>
                        <img src={process.env.PUBLIC_URL+'/img/Image_Resume_4.png'} alt="Yvonne Vogt" style={{maxWidth: '100%', marginBottom: '3rem'}}/>
                        <br/>
                        <ImageButton href="mailto:yvonne.vogt@siebenelf.com" style={{marginBottom: '5rem'}}>
                            <img src={process.env.PUBLIC_URL + "/img/say_hi_lila.svg"} alt="say hi!"/>
                        </ImageButton>
                    </div>
                }>
                <h3 style={{marginTop: '0.5rem'}}>Hi, I'm Yvonne.</h3>
                <p>
                    I’m a passionate and dedicated UX/UI Designer with over 10 years experience as a Graphic Designer and Senior Art Director. 
                    I focus on human centered design to create satisfying solutions and to make complex content easy to understand. 
                    I don’t create designs solely meant to be beautiful, but useful at the same time. 
                </p>
                <p>
                    I do so by creating positively memorable experiences with strategic and innovative approaches. 
                    By reducing unnecessary complexity and eliminating frustrating experiences. 
                    I’m focusing on ease of use, stunning but subtle designs and gently leading the user to the intended outcome. 
                </p>
                <p>
                    I use my empathy to deeply understand the user’s needs and my logic to grasp what the task requires 
                    and combine all of that to a solution able to boost efficiency and satisfaction. 
                    Because a frustrated user won’t be able to provide the best performance. Or will just leave.
                </p>
                <ImageButton href={process.env.PUBLIC_URL+'/docs/Yvonne_Vogt_Resume.pdf'}>        
                    <img src={process.env.PUBLIC_URL + "/img/read_resume.svg"} alt="say hi!" style={{marginTop: '3rem', marginBottom: '3rem'}}/>
                </ImageButton>
            </InfoLeft>
            <Spacer height={'10rem'}/>
        </div>
  );
}

export default About;
