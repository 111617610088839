import React from 'react';

function InfoLeft(props) {
    const className = props.class ? props.class : "";

    return (
        <section className={"infoLeft "+className}>
            <div className="infoColumn">
                {props.children}
            </div>
            {props.image && 
                <div className="imageColumn">
                    <img src={props.image.src} alt={props.image.alt} style={props.image.style}/> 
                    {props.button &&
                        props.button
                    }
                </div>
            }
            {props.content && 
                <div className="contentColumn">
                    {props.content}
                </div>
            }
        </section>
    );
}

export default InfoLeft;
