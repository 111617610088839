import React from 'react';
import ImageButton from '../atoms/ImageButton';
import { Link, useLocation } from 'react-router-dom';


function Header() {
    const cssClass = useLocation().pathname === '/about' ? "about" : "";
    return (
        <header> 
            <div id="logo">
                <ImageButton href="/">
                    <img src={process.env.PUBLIC_URL+'/img/logo UX - UI.svg'} alt="Yvonne Vogt // UX &amp; UI Design"/>
                </ImageButton>
            </div>
            <div id="info">
                <ul>
                    <li>
                        <Link to="/about" className={cssClass}>About Me</Link>
                    </li>
                </ul>
            </div>
        </header>
    );
}

export default Header;
