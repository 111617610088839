import React from 'react';
import { Link } from 'react-router-dom';

function ImageButton(props) {
  const buttonStyle = props.style ? props.style : {}

  if(props.href && props.href.startsWith('/') && !props.href.startsWith(process.env.PUBLIC_URL+'/docs') && !props.href.startsWith(process.env.PUBLIC_URL+'/img')) {
    return (
      <Link to={props.href} className="imageButton" style={buttonStyle}>
        {props.children}
      </Link>
    );
  }
  if(props.href) {
    return (
      <a href={props.href} className="imageButton" target="_blank" rel="noopener noreferrer" style={buttonStyle}>
        {props.children}
      </a>
    );
  }
}

export default ImageButton;
