import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ScrollToTop from './molecules/scrollToTop';
import Header from './molecules/header';
import Footer from './molecules/footer';
import Main from './pages/main';
import Vela from './pages/vela';
import About from './pages/about';
import Imprint from './pages/imprint';
import Vocablah from './pages/vocablah';

function App() {
  return (
    <BrowserRouter basename={''}>
      <Header />
      <ScrollToTop/>
      <Switch>
          <Route path="/" exact component={Main}/>
          <Route path="/vela" exact component={Vela}/>
          <Route path="/vocablah" exact component={Vocablah}/>
          <Route path="/about" exact component={About}/>
          <Route path="/imprint" exact component={Imprint}/>
          <Redirect to='/' />
      </Switch>
      <Footer/>
  </BrowserRouter>
  );
}

export default App;
