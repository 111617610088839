import React from 'react';

function Abstract(props) {
  const className = props.class ? props.class : '';
  return (
        <section className={"abstract "+className}>
          <article>
            {props.children}
          </article>
        </section>
  );
}

export default Abstract;
