import React from 'react';

function scrollToElement(elementId) {
  const header = document.getElementsByTagName('header');
  let distanceTop = 200;
  if(header) {
    distanceTop = header[0].getBoundingClientRect().height + 50;
  }
  const elementBoundingRect = document.getElementById(elementId).getBoundingClientRect();
  window.scrollBy(0, elementBoundingRect.top - distanceTop);
  document.getElementById('pageNavigationOpen').checked=false;
}

function PageNavigation(props) {
  return (
    <nav className={'pageNavigation'}>
      <div className={'pageNavigationWrapper'}>
        <input type="checkbox" id="pageNavigationOpen" hidden="true"/>
        <label for="pageNavigationOpen" id="pageNavigationOpenLabel"></label>
        <ul>
          {props.links && props.links.map(link => (
            <li><a onClick={() => {
              scrollToElement(link.anchor);
            }}>{link.title}</a></li>
          ))}
        </ul>
      </div>
    </nav>
  );
}

export default PageNavigation;