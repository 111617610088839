import React from 'react';
import Intro from '../molecules/intro';
import InfoLeft from '../molecules/infoLeft';
import Image from '../molecules/image';
import Container from '../molecules/container';
import Spacer from '../molecules/spacer';
import Analysis from '../molecules/analysis';
import PageNavigation from '../molecules/pageNavigation';

import AnalysisColumn from '../atoms/AnalysisColumn';
import ImageButton from '../atoms/ImageButton';

function Vocablah() {
    return (
        <div className="main">
            <PageNavigation links={
                [
                    { 'title': 'Vocablah', 'anchor': 'rocket-surgery'}, 
                    { 'title': 'Competitive Analysis', 'anchor': 'comp-analysis' },
                    { 'title': 'User Persona', 'anchor': 'user-persona'},
                    { 'title': '5-W-Method', 'anchor': '5-w' },
                    { 'title': 'User Journey Map', 'anchor': 'user-journey-map' },
                    { 'title': 'Wireframes', 'anchor': 'wireframes' },
                    { 'title': 'Usability Test Report', 'anchor': 'usability-test-report' },
                    { 'title': 'Visual Design', 'anchor': 'visual-design' }
                ]
            }/>
            <Intro>
                <img id="rocket-surgery" src={process.env.PUBLIC_URL+'/img/vocablah_logo_orange.svg'} alt="Vocablah" style={{marginBottom: '-1rem', height: '5rem'}}/>
                <p>
                    Learning a new language is no rocket surgery.
                </p>
            </Intro>
            <InfoLeft image={{src: process.env.PUBLIC_URL+'/img/vocablah_mockup.png', alt:'Vocablah'}} class="a3">
                <h3>Start playing. <br/>We’ll get to know you.</h3>
                <p>
                    As you play, we figure out which words you know and which ones you need a little help with. 
                    We keep practicing with you until you master the tough ones. 
                </p>
                <p>
                    Let us know which words you want to focus on, and we’ll prioritize those.
                </p>
                <br/>
                <h3>As your vocabulary grows, <br/>Vocablah grows with you.</h3>
                <p>
                    Vocablah is a platform for lifelong learning, growing with you every step of the way. 
                    As you improve, the words that you learn will become more and more advanced. 
                    And, with our easy-to-use progress-monitoring tools, you can always look back to see how far you’ve come.
                </p>
                <br/>
                <h3>Goal</h3>
                <p>
                    The most important thing when learning a new language is seek balance. 
                </p>
                <p>
                    This means learning a new language involves listening, speaking, reading, writing, sometimes even a new alphabet and writing format. 
                    If you focus exclusively on just one activity, the others fall behind.
                </p>
                <br/>
                <h3>Problem</h3>
                <p>
                    How can you turn a complex, time intensive task that requires dedication, persistence and hard work into a pleasurable experience?
                </p>
                <br/>
                <h3>My Role</h3>
                <p>
                    I was responsible for the entire design process including user research and analysis, wireframing and prototyping, user testing, branding and visual design.
                </p>
                <br/>
                <h3>Tools</h3>
                <div style={{ display: 'flex' }}>
                    <ul style={{ paddingRight: '3em' }}>
                        <li>Pen &amp; Paper</li>
                        <li>Balsamiq</li>
                        <li>Sketch</li>
                        <li>Adobe XD</li>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>Typeform</li>
                        <li>Survey Monkey</li>
                        <li>Google Forms</li>
                        <li>Zoom</li>
                    </ul>
                    <ul>
                        <li>Jira</li>
                        <li>Confluence</li>
                        <li>Trello</li>
                        <li>Miro</li>
                        <li>Coggle</li>
                        <li>Usability Hub</li>
                        <li>Optimal Sort</li>
                        <li>Excel</li>
                        <li>Power Point</li>
                        <li>Word</li>
                        <li>Draw.io</li>
                    </ul>
                </div>
            </InfoLeft>
            <Spacer height={'7rem'}/>
            <Image src={process.env.PUBLIC_URL+'/img/vocablah-process.svg'} alt='Process' class='a6'/>
            <Spacer height={'7rem'}/>
            <Container>
                <h1 style={{marginBottom: '0'}} id="comp-analysis">Competitive Analysis</h1>
            </Container>
            <Analysis>
                <AnalysisColumn 
                    img={{src: process.env.PUBLIC_URL+'/img/flashcards.png', alt: 'Flashcards'}}
                    head={'Flashcards'}
                    text={<div>
                            <ImageButton href={'https://play.google.com/store/apps/details?id=com.tq.flashcard&hl=en'}>
                                <img alt='Get it on Google Play' src={process.env.PUBLIC_URL+'/img/en_badge_web_generic.png'} style={{height: 'inherit', maxWidth: '55%', marginTop: '1em'}}/>
                            </ImageButton>
                            <p>
                                Whether you are studying for the next exam or trying to learn a new language - 
                                the Flashcards app is designed to provide you with an excellent study tool to accelerate your learning and success.
                            </p>
                            <p>
                                Create your own flashcards and begin studying immediately!
                            </p>
                        </div>}
                    />
                <AnalysisColumn 
                    img={{src: process.env.PUBLIC_URL+'/img/flashcards_maker.png', alt: 'Flashcards Maker'}}
                    head={'Flashcards Maker'}
                    text={<div>
                            <ImageButton href={'https://play.google.com/store/apps/details?id=com.lexilize.fc&hl=en'}>
                                <img alt='Get it on Google Play' src={process.env.PUBLIC_URL+'/img/en_badge_web_generic.png'} style={{height: 'inherit', maxWidth: '55%', marginTop: '1em'}}/>
                            </ImageButton>
                            <p>
                                Just imagine that learning of foreign language can be like a game and has nothing to do with boring memorization. 
                            </p>
                            <p>
                                Lexilize Flashcards – the application, which make the learning process much easier. 
                            </p>
                            <p>                                    
                                You can quickly make your dictionary with transcriptions, samples and an associative images and easily learn new vocabulary with 5 different games.
                            </p>
                        </div>}
                    />
                <AnalysisColumn 
                    img={{src: process.env.PUBLIC_URL+'/img/lingvist.png', alt: 'Lingvist'}}
                    head={'Lingvist'}
                    text={<div>
                            <ImageButton href={'https://play.google.com/store/apps/details?id=io.lingvist.android&hl=en'}>
                                <img alt='Get it on Google Play' src={process.env.PUBLIC_URL+'/img/en_badge_web_generic.png'} style={{height: 'inherit', maxWidth: '55%', marginTop: '1em'}}/>
                            </ImageButton>
                            <p>
                                Language learning can be frustrating when you cannot remember a word that you have seen or learned before. 
                            </p>
                            <p>
                                Also, it is not easy to find an exercise that is right for your level and can be done within minutes. We know that feeling - Lingvist can help.
                            </p>
                        </div>}
                    />
            </Analysis>
            <Spacer height={'3rem'}/>
            <Image src={process.env.PUBLIC_URL+'/img/mockup_onboarding.png'} alt='Process' class='a6' img={{style: {maxWidth: '100%'}}}/>
            <Spacer height={'3rem'}/>
            <Container>
                <h1 id="user-persona">User Persona</h1>
                <p>
                    It’s very important to understand who will be using the product. 
                    In order to solve a real user problem, you need to have a clear problem statement in mind; in order to write this problem statement, 
                    you first need to understand your users and their needs.
                </p>
                <h3>Who are the users and what do they need?</h3>
                <p>
                    I created a user persona to understand what my users really need. <br/>
                    To get a grasp of them I needed to ask some questions. 
                </p>
                <p>
                    What I asked them was (simplified):
                </p>
                <ul>
                    <li>Who are they?</li>
                    <li>What is their main goal?</li>
                    <li>What is their main barrier to achieving this goal?</li>
                </ul>
            </Container>
            <InfoLeft image={{src: process.env.PUBLIC_URL+'/img/persona_chris.png', alt:'Chris', style: {maxWidth: '100%'}}} class="a3">
                <h3 style={{marginTop: '0.5rem'}}>Code is like humor. <br/>When you have to explain it, It’s bad.</h3>
                <ul>
                    <li>Chris, 32 years, from Stuttgart </li>
                    <li>studied software engineering </li>
                    <li>works at a start-up company for 3 years </li>
                    <li>plans and coordinates upcoming tasks </li>
                    <li>“speaks” 11 different programming languages, covering a variety of technologies</li>
                </ul>
                <br/>
                <h3>Needs &amp; Goals</h3>
                <ul>
                    <li>likes to learn a lot more, especially languages </li>
                    <li>needs to see progress </li>
                    <li>quick access to reference </li>
                    <li>the possibility to learn on-the-go</li>
                </ul>
                <br/>
                <h3>Chris struggles with …</h3>
                <ul>
                    <li>getting annoyed pretty easily.</li>
                    <li>losing his motivation quite fast.</li>
                    <li>being distracted.</li>
                </ul>
            </InfoLeft>  
            <Spacer height={'3rem'}/>
            <Container class={'w5'}>
                <h1 id="5-w">5-W-Method</h1>
                <h3>Who</h3>
                <p>25 to 34 year old people improve language skills</p>
                <h3>What</h3>
                <p>Vocabulary app motivation through gamification</p>
                <h3>When</h3>
                <p>Anytime you can spare at least 10 min. without distraction</p>
                <h3>Where</h3>
                <p>Everywhere (almost) no internet required</p>
                <h3>Why</h3>
                <p>To gain confidence and communicate on a new level</p>
            </Container>    
            <Spacer height={'3rem'}/>
            <Container>
                <h1 id="user-journey-map">User Journey Map</h1>
            </Container>
            <Container style={{marginTop: '-2em'}}>
                <p>
                    To ensure my persona find what they needed, I created some user journeys based on how they would accomplish their goals. 
                    By creating user flows, I could see what I needed to develop so my personas’ needs were satisfied.
                </p>
            </Container>
            <Image src={process.env.PUBLIC_URL+'/img/user_journey_map.png'} alt='User Journey Map' class='a6' img={{style: { maxWidth: '100%'}}}/>
            <Spacer height={'2rem'}/>
            <Container>
                <h1 id="wireframes">Wireframes</h1>
            </Container>
            <Image src={process.env.PUBLIC_URL+'/img/wireframes_VOCABLAH.jpg'} alt='User Journey Map' class='a6' img={{style: { maxWidth: '100%'}}}/>
            <Spacer height={'2rem'}/>
            <Container>
                <p>
                    Once I had established the user flows I could start prototyping the primary screens. 
                    Using the rapid prototyping method a collection of low-fidelity paper prototypes were created. 
                    After some paper prototyping adjustments, wireframes, mid- and high-fidelity prototypes were created.
                </p>
            </Container>
            <Spacer height={'5rem'}/>
            <InfoLeft image={{src: process.env.PUBLIC_URL+'/img/usability_test_report.png', alt:'Usability Test Report', style: {top: '-12rem', left: '-2rem'}}} class="a3">
                <h1 id="usability-test-report">Usability Test Report</h1>
                <p>
                    The usability test was conducted to gain information about the user's perception of the click dummy. 
                    The user test results were mixed. Some said the onboarding process is too long and that they want to skip the onboarding process.
                </p>
                <p> 
                    A few had frictions with the missing explanation of the bottom navigation. 
                    And almost all had problems with the size of the buttons and thought that they are inconspicuous. 
                    One user said in the interview, that there is no recognizable USP.
                </p>
                <br/>
                <h1 id="visual-design">Visual Design</h1>
                <h3>Visual Design and Branding</h3>
                <p>
                    After polishing the UI design, I created a design language system document, in which I detailed how color, typography, UI elements, 
                    and language should be used throughout the app to achieve a cohesive product.
                </p>
            </InfoLeft>  
            <Spacer height={'12rem'}/>
            <Image src={process.env.PUBLIC_URL+'/img/visual_design.png'} alt='Visual Design' class='a6' img={{style: { maxWidth: '100%'}}}/>
            <Spacer height={'5rem'}/>
            <Image src={process.env.PUBLIC_URL+'/img/vocablah_mockup_bottom.jpg'} alt='Vocablah Mockup' class='a6' img={{style: { maxWidth: '100%'}}}/>
        </div>
    );
}

export default Vocablah;
