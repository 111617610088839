import React from 'react';

function ImageList(props) {
  const className = props.class ? props.class : "";
  return (
        <section className={"imageList "+className}>
          <div>
            {props.children}
          </div>
        </section>
  );
}

export default ImageList;
