import React from 'react';

function Intro(props) {
  return (
        <section className="intro">
          <div>
            {props.children}
          </div>
        </section>
  );
}

export default Intro;
