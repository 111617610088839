import React from 'react';

import Intro from '../molecules/intro';
import InfoLeft from '../molecules/infoLeft';
import InfoRight from '../molecules/infoRight';
import Spacer from '../molecules/spacer';

import ImageButton from '../atoms/ImageButton';

function Main() {
  return (
        <div className="main">
            <Intro>
                <p>Hi, I'm Yvonne.</p>
                <p>I'm a designer focusing in creating experiences at the intersections of usability and design.</p>
                <ImageButton href="mailto:yvonne.vogt@siebenelf.com">
                    <img src={process.env.PUBLIC_URL + "/img/say_hi_orange.svg"} alt="say hi!"/>
                </ImageButton>
            </Intro>
            <Spacer height={'15rem'}/>
            <InfoLeft image={{src: process.env.PUBLIC_URL+'/img/vela_mockup_2.png', alt:'VELA', style:{top: '-7rem', left: '0', marginBottom: '-10rem'}}}>
                <img src={process.env.PUBLIC_URL+'/img/vela.svg'} alt="VELA" style={{marginBottom: '2rem'}}/>
                <h3>VELA is your customized weather forecast app focussed on outdoor activities that helps you to find your spots and tracks with the perfect conditions</h3>
                <ImageButton href="/vela">
                    <img src={process.env.PUBLIC_URL+'/img/get_started.svg'} alt="VELA"/>
                </ImageButton>
            </InfoLeft>
            <Spacer height={'17rem'}/>
            <InfoRight image={{src: process.env.PUBLIC_URL+'/img/vocablah_mockup_3.png', alt:'Vocablah', style:{right: '-5rem', top: '-7rem'}}}>
                <img src={process.env.PUBLIC_URL+'/img/vocablah_logo.svg'} alt="Vocablah" style={{marginBottom: '3rem'}}/>
                <h3>Learning a new Language is no Rocket Surgery</h3>
                <ImageButton href="/vocablah">
                    <img src={process.env.PUBLIC_URL+'/img/go-to-showcase.svg'} alt="Vocablah"/>
                </ImageButton>
            </InfoRight>
            <Spacer height={'15rem'}/>
        </div>
  );
}

export default Main;
