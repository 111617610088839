import React from 'react';

function AnalysisColumn(props) {
  return (
    <div className="analysisColumn">
      <img src={props.img.src} alt={props.img.alt}/>
      <h3>{props.head}</h3>
      <p>
        {props.text}
      </p>
    </div>
  );
}

export default AnalysisColumn;
