import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Footer() {
    const location = useLocation();

    if(location.pathname === '/imprint') {
        return null;
    }
    return (
        <footer> 
            <div id="contact">
                <p>Yvonne Vogt&nbsp;&nbsp;//  UX&nbsp;&amp;&nbsp;UI&nbsp;Design</p>
                <p>
                    <a href="mailto:yvonne.vogt@siebenelf.com">yvonne.vogt@siebenelf.com</a><br />
                    <a href="tel:+4917664930711">+49 176.64930711</a>
                </p>
                <p>
                    <a href="https://www.linkedin.com/in/yvonne-vogt-800479179/" title="linkedin" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL+"/img/linked-in_logo_fff.svg"} alt="linkedin" /></a>
                    <a href="https://www.xing.com/profile/Yvonne_Vogt10/cv" title="xing" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL+"/img/xing_logo_fff.svg"} alt="xing" /></a>
                    <a href="https://www.behance.net/yvonnevogt" title="behance" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL+"/img/behance_logo_fff.svg"} alt="behance" /></a>
                </p>
            </div>
            <div id="imprint">
                <Link to="/imprint">Imprint</Link>
            </div>
        </footer>
    );
}

export default Footer;
