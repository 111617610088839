import React from 'react';

function Container(props) {
  const className = props.class ? props.class : "";
  const containerStyle = props.style ? props.style : {}

  return (
        <section className={"textBlock "+className} style={containerStyle}>
          <div>
            {props.children}
          </div>
        </section>
  );
}

export default Container;
